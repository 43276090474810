import axios from 'axios'
import {SERVER_HOST} from './../common/portConfig'
var instance = axios.create({
  headers: {
    'Authorization': ''
  }
});
instance.interceptors.request.use(
  config => {
    config.headers.Authorization = sessionStorage.getItem('Authorization');
    return config
  }, err => {
    return Promise.reject(err)
  });

//登录日志分页列表
export const reqAdminLoginLogPage=params=>{return instance.get(`${SERVER_HOST}/adminLoginLog/page`,{params:params})};
