<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          :to="{ path: '/' }">
          <b>首页</b></el-breadcrumb-item>
        <el-breadcrumb-item>登录日志</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="24" class="warp-main">
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input v-model="filters.username" placeholder="请输入用户名" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="filters.loginIp" placeholder="请输入IP" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="getLoginLogList(0)" icon="search">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="goReset" icon="search">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-if="permissionButton.add" @click="goAddPage">添加</el-button>
          </el-form-item>
        </el-form>
      </el-col>

      <el-table :data="loginLogPage" highlight-current-row v-loading="listLoading" @selection-change="selsChange"
                style="width: 100%;" border>
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="username" label="用户名" min-width="120" align="center"></el-table-column>
        <el-table-column prop="loginIp" label="IP" min-width="150" align="center" sortable></el-table-column>
        <el-table-column prop="loginStatus" label="登录状态" width="150" align="center" sortable>
          <template slot-scope="scope">
            <span v-bind:class="{temporary: (scope.row.loginStatus)==='1'}">{{scope.row.loginStatus==='0'?'登录成功':'登录失败'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="formatCreateTime" label="登录时间" width="180" align="center" sortable></el-table-column>
      </el-table>
      <el-col :span="24" class="table-footer">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes=[10,15,20,25,50]
          :page-size="pageSize"
          :current-page.sync="pageNum"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total" style="float:right;">
        </el-pagination>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
  import {reqAdminLoginLogPage} from '../../../api/loginLog-api'
  import permission from '../../../common/permission'

  export default {
    data() {
      return {
        filters: {
          loginIp: '',
          username: ''
        },
        loginLogPage: [],
        total: 0,
        pageNum: 1,
        pageSize: 15,
        listLoading: false,
        sels: [],
        permissionButton: new permission.PermissionButton
      }
    },
    methods: {
      handleCurrentChange(val) {
        this.pageNum = val;
        this.getLoginLogList();
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageSize = val;
        this.getLoginLogList();
      },
      selsChange(){

      },
      goReset() {
        this.filters.username = "";
        this.filters.loginIp = "";
        this.getLoginLogList()

      },
      getLoginLogList(status) {
        this.listLoading = true;
        let param = {
          pageNum: status===0?0:this.pageNum,
          pageSize: this.pageSize,
          username: this.filters.username,
          loginIp: this.filters.loginIp
        };
        reqAdminLoginLogPage(param).then(res => {
          this.listLoading = false;
          if (res.data.code === 1) {
            this.loginLogPage=res.data.data.records;
            this.total=res.data.data.total;
          } else {
            this.$message({
              message: '查询失败',
              type: 'error'
            });
          }
        }).catch(() => {
          this.listLoading = false;
          this.$message({
            message: '查询失败',
            type: 'error'
          });
        })
      },
    }, mounted() {
      var permissionId = sessionStorage.getItem('permissionId');
      permission.getPermissionButton(permissionId, this.permissionButton);
      this.getLoginLogList();
    }
  }
</script>

<style scoped>

</style>
